"use client"
import classNames from "classnames";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { CSSProperties, useEffect, useState } from "react";

function TargetsNav() {
  const t = useTranslations();
  const path = usePathname();
  const [isMobile, setIsMobile] = useState(false);
  const isActive = (segment: string) => path.split("/").includes(segment);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = [
    {
      id: "customer",
      title: t("links.customer"),
      href: "/home/customer",
      text: <>
        {t('targets-nav.customer.t1')}
        <br /> <br />
        {t('targets-nav.customer.t2')}
        <br /><br />
        {t('targets-nav.customer.t3')}
      </>,
      image: "/assets/hero_customer.avif",
      bgPosition: "45%",
      bgOverlay: true,
    },
    {
      id: "merchant",
      title: t("links.merchant"),
      href: "/home/merchant",
      text: <>
        {t('targets-nav.merchant.t1')}
        <br /><br />
        {t('targets-nav.merchant.t2')}
        <br /><br />
        {t('targets-nav.merchant.t3')}
      </>,
      image: "/assets/hero_merchant.avif",
    },
    {
      id: "partner",
      title: t("links.partner"),
      href: "/home/partner",
      text: <div className="flex text-center items-center justify-center h-full w-full font-bold">
        Coming Soon
      </div>,
      image: "/assets/hero_bg.avif",
    }
  ]


  return (
    <section className="p-main py-4">
      <div className="flex flex-wrap lg:flex-nowrap justify-center gap-6 lg:gap-10">
        {items.map((item, i) => (
          <article key={i}
            className={classNames("bg-secondary fade-bg flex flex-col w-[32rem] justify-between gap-2 p-4", {
              "hovered": hoveredIndex === i || isMobile,
              "hidden": isActive(item.id),
              "bg-overlay": item.bgOverlay,
            })}
            onMouseEnter={() => setHoveredIndex(i)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{
              ["--background-position" as keyof CSSProperties]: item.bgPosition || "center",
              ["--background-image" as keyof CSSProperties]: hoveredIndex === i || isMobile ?
                `url(${item.image})` : undefined,
            }}
          >
            <h1 className="text-gold text-xl md:text-2xl z-10">
              {item.title}
            </h1>
            <div className="w-full min-h-80 z-10">
              {item.text}
            </div>
            <Link
              scroll={false}
              href={item.href}
              className={"link-btn text-center z-10"}
            >
              {t("links.go-to-page")}
            </Link>
          </article>
        ))}
      </div>
    </section>
  );

}

export default TargetsNav;