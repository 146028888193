"use client"
import { useTranslations } from "next-intl";
import Link from "next/link";
import { BsArrowRight } from "react-icons/bs";
import { BlogPost } from "../../datastore/blog-post/blog-post.model";

interface BlogPostCardProps {
  blogPost: BlogPost,
  linkText?: string;
  className?: string;
}

function BlogPostCard({ linkText, blogPost, className }: BlogPostCardProps) {
  const t = useTranslations();

  return (
    <article className={`min-w-64 w-fit h-full ${className}`}>
      <Link href={`/blog/${blogPost.id}`} tabIndex={-1}>
        <header className="bg-gold min-h-48 bg-center bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(${blogPost.cover?.url})` }}
        >
        </header>
      </Link>
      <div className="flex flex-col p-3 bg-secondary">
        <div className="mb-3 items-center">
          <Link href={`/blog/${blogPost.id}`} tabIndex={-1}>
            <h5 className="text-lg xl:text-xl font-semibold text-gold">{blogPost.title}</h5>
          </Link>
          {/*
           <span className="uppercase text-primary-main text-xs">{toDateString(blogPost.date)}</span>
           */}
        </div>
        <p className="mb-2 truncate min-w-full">
          {blogPost.description}
        </p>
        <div className="flex justify-end">
          <Link href={`/blog/${blogPost.id}`} className="text-xs font-bold w-fit">
            {linkText ?? t('news.read-more')}
            <BsArrowRight className="ml-2 inline" size={16} />
          </Link>
        </div>
      </div>
    </article>
  );
}

export default BlogPostCard;