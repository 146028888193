import { AxiosError } from "axios";
import { mapPage, mapper } from "../../common/mapper";
import { PageDto } from "../../common/models/page/page.dto";
import { Page } from "../../common/models/page/page.model";
import { ClientAPI, get } from "../../common/utils/api";
import { BlogPostDto } from "./blog-post.dto";
import { BlogPost, BlogPostType } from "./blog-post.model";


export async function getBlogPostsPage(page?: number, pageSize?: number, locale?: string): Promise<Page<BlogPost> | AxiosError> {
  try {
    const res = await get<PageDto<BlogPostDto>>(`/blog-posts`,
      {
        'accept-language': locale
      },
      {
        parameters: {
          page,
          pageSize,
          type: BlogPostType.news
        }
      },
      ClientAPI
    );
    return mapPage<BlogPostDto, BlogPost>(res.data, "BlogPostDto", "BlogPost");
  } catch (error) {
    return error as AxiosError
  }
}

export async function getEventsPage(page?: number, pageSize?: number, locale?: string): Promise<Page<BlogPost> | AxiosError> {
  try {
    const res = await get<PageDto<BlogPostDto>>(`/blog-posts`,
      {
        'accept-language': locale
      },
      {
        parameters: {
          page,
          pageSize,
          type: BlogPostType.event
        }
      },
      ClientAPI
    );
    return mapPage<BlogPostDto, BlogPost>(res.data, "BlogPostDto", "BlogPost");
  } catch (error) {
    return error as AxiosError
  }
}

export async function getLatestEvents(amount = 3, locale?: string): Promise<BlogPost[] | AxiosError> {
  try {
    const res = await get<PageDto<BlogPostDto>>(`/blog-posts`,
      {
        'accept-language': locale
      },
      {
        parameters: {
          pageSize: amount,
          type: BlogPostType.event
        }
      },
      ClientAPI
    );

    return mapper.mapArray<BlogPostDto, BlogPost>(res.data.data.slice(0, amount), "BlogPostDto", "BlogPost");
  } catch (error) {
    return error as AxiosError
  }
}

export async function getBlogPostById(id: string, locale?: string): Promise<BlogPost | AxiosError> {
  try {
    const res = await get<BlogPostDto>(`/blog-posts/${id}`,
      {
        'accept-language': locale
      }, undefined
      ,
      ClientAPI
    );
    return mapper.map<BlogPostDto, BlogPost>(res.data, "BlogPostDto", "BlogPost");
  } catch (error) {
    return error as AxiosError
  }
}