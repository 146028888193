'use client';

import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { CgChevronRightO, CgChevronLeftO } from "react-icons/cg";

interface ImageCarouselProps {
  components: ReactNode[];
  interval?: number;
  indexIndicators?: boolean;
  slideButtons?: boolean;
}

function Carousel({ components, interval = 0, indexIndicators = components.length > 1, slideButtons = components.length > 1 }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);

  useEffect(() => {
    if (interval <= 0) return;
    const _interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === components.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(_interval);
  }, [components, interval, currentIndex]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX !== null && touchEndX !== null) {
      const touchDelta = touchStartX - touchEndX;

      if (Math.abs(touchDelta) > 50) {
        // A significant swipe detected (threshold = 50px)
        if (touchDelta > 0) {
          // Swiped left
          setCurrentIndex((prevIndex) =>
            prevIndex === components.length - 1 ? 0 : prevIndex + 1
          );
        } else {
          // Swiped right
          setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? components.length - 1 : prevIndex - 1
          );
        }
      }
    }

    // Reset touch coordinates
    setTouchStartX(null);
    setTouchEndX(null);
  };

  // Responsive height styling for the carousel
  const heightStyles = 'h-[80svh] lg:h-[500px] xl:h-[620px]';

  return (
    <section
      className={`relative overflow-hidden w-full ${heightStyles}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Image Slider */}
      {components.map((component, index) => (
        <div
          key={index}
          className="flex absolute transition-transform duration-[1000ms] ease-in-out w-full h-full"
          style={{ transform: `translateX(${-(currentIndex - index) * 100}%)` }}
        >
          {component}
        </div>
      ))}
      <div className='relative w-1/2 float-right h-full'>
        {indexIndicators &&
          <div className="absolute bottom-4 md:bottom-8 -left-4 md:-left-8 flex space-x-2 md:space-x-4">
            {components.map((_, index) => (
              <div
                key={index}
                className={classNames("w-2 md:w-4 aspect-square rounded-full transition-opacity duration-300", {
                  "bg-gold": currentIndex === index,
                  "bg-light": currentIndex !== index
                })}
              />
            ))}
          </div>
        }
      </div>
      {slideButtons &&
        <>
          <button className="absolute hidden lg:block bottom-14 md:bottom-[50%] left-8 sm:left-10 md:left-6"
            onClick={() => setCurrentIndex((prevIndex) => (prevIndex === 0 ? components.length - 1 : prevIndex - 1))}
          >
            <CgChevronLeftO size={44} className='opacity-70 hover:opacity-90 transition-all active:scale-75 md:active:scale-90 scale-90 md:scale-100' />
          </button>
          <button className="absolute hidden lg:block bottom-14 md:bottom-[50%] right-8 sm:right-10 md:right-16"
            onClick={() => setCurrentIndex((prevIndex) => (prevIndex === components.length - 1 ? 0 : prevIndex + 1))}
          >
            <CgChevronRightO size={44} className='opacity-70 hover:opacity-90 transition-all active:scale-75 md:active:scale-90 scale-90 md:scale-100' />
          </button>
        </>
      }
    </section>
  );
}

export default Carousel;