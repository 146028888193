"use client";

import classNames from "classnames";
import { useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { getBlogPostsPage, getEventsPage } from "../../datastore/blog-post/blog-post.api";
import BlogPostCard from "../Cards/BlogPostCard";
import { Page } from "../../common/models/page/page.model";
import { BlogPost } from "../../datastore/blog-post/blog-post.model";
import { IoMdArrowBack } from "react-icons/io";
import { IoMdArrowForward } from "react-icons/io";
import { useTranslations } from "next-intl";

function NewsAndEventsSection() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <NewsAndEvents />
    </QueryClientProvider>
  )
}

function NewsAndEvents() {
  const t = useTranslations();
  const [activeTab, setActiveTab] = useState("events");
  const [page, setPage] = useState(1);
  const { data: events, isLoading: eventsLoading } = useQuery({
    queryKey: ["events", page],
    queryFn: async () => {
      const res = await getEventsPage(page, 4);
      if (res instanceof Error) {
        throw res;
      }
      if (res.content.length === 0) {
        setActiveTab("news");
      }
      return res
    },
    enabled: activeTab === "events",
  });

  const { data: news, isLoading: newsLoading } = useQuery({
    queryKey: ["news", page],
    queryFn: async () => {
      const res = await getBlogPostsPage(page, 4);
      if (res instanceof Error) {
        throw res;
      }
      return res
    },
    enabled: activeTab === "news",
  });

  const loading = eventsLoading || newsLoading;
  const activeData = activeTab === "events" ? events : news;
  const maxPages = Math.min(activeData?.totalPages ?? 0, 3);
  return (
    <section className="p-main">
      <div className="flex flex-col lg:flex-row gap-8 justify-around">
        <section>
          <h2 className="mb-8">{t('news.section-title')}</h2>
          <div className="flex flex-col gap-4 items-start">
            <button
              onClick={() => setActiveTab("events")}
              className={classNames("border-l-4 pl-6 text-2xl font-montserrat", {
                "font-semibold border-gold text-gold": activeTab === "events",
                "border-transparent": activeTab !== "events",
              })}
            >
              {t('news.upcoming-events')}
            </button>
            <button
              onClick={() => setActiveTab("news")}
              className={classNames("border-l-4 pl-6 text-2xl font-montserrat", {
                "font-semibold border-gold text-gold": activeTab === "news",
                "border-transparent": activeTab !== "news",
              })}>
              {t('blog.news-title')}
            </button>
          </div>
        </section>
        <section>
          <NewsCards data={activeData} />
          {activeData && <div className="flex mt-8 justify-between">
            <button
              onClick={() => setPage(page - 1)}
              className="link-btn flex items-center gap-2"
              disabled={loading || page < maxPages}
            >
              <IoMdArrowBack size={36} />
              <span className="hidden md:block">{t('labels.back')}</span>
            </button>
            <div className="flex gap-4 justify-center items-center">
              {Array.from({ length: maxPages }, (_, i) => i + 1).map((i) => (
                <button
                  onClick={() => setPage(i)}
                  key={i}
                  className={classNames("w-4 h-4 rounded-full", {
                    "bg-gold": i === page,
                    "bg-light": i !== page
                  })}
                />
              ))}
            </div>
            <button
              onClick={() => setPage(page + 1)}
              className="link-btn flex items-center gap-2"
              disabled={loading || page >= maxPages}
            >
              <span className="hidden md:block">{t('labels.next')}</span>
              <IoMdArrowForward size={36} />
            </button>
          </div>}
        </section>

      </div >
    </section >
  );
}

function TilesSkeleton() {
  const amount = typeof window === "undefined" ? 4 : window.innerWidth >= 768 ? 4 : 1
  return (
    <div className="grid md:grid-cols-2 w-full gap-6">
      {Array.from({ length: amount }, (_, i) => i + 1).map((i) => (
        <article key={i} className={classNames(`min-w-full w-fit h-96 skeleton`)} />
      ))}
    </div>
  );
}

function NewsCards({ data }: { data?: Page<BlogPost> }) {

  if (!data) {
    return <TilesSkeleton />
  }

  return (
    <>
      <div className="grid md:grid-cols-2 w-full gap-6">
        {data.content.map((event, i) => (
          <BlogPostCard blogPost={event} key={i} className="w-[360px] sm:w-[425px] md:w-[360px]" />
        ))}
      </div>
    </>
  )
}

export default NewsAndEventsSection;