import { BaseModel } from "../../common/models/base/base.model";
import { Image } from "../../common/models/image/image.model";

export interface BlogPost extends BaseModel {
  title: string;
  description: string;
  cover: Image | null;
  content: string // markdown
  date: Date | null
  type: BlogPostType
}

export enum BlogPostType {
  event = "EVENT",
  news = "NEWS"
}